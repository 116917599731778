import React from 'react';
import { config } from '@/config';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const DNC_STATUSES = [
  'Do Not Call',
  'Do Not Contact',
  'DNC',
  'DNC/Take Off List',
  'DNC - Do Not Call',
  'Take Off List',
  'TOL - Take Off List',
  'Bad Lead',
  'Deceased'
];

export const CONTACT_TYPES = {
  CONTACT: 'contact',
  TPM: 'tpm',
  FUNERAL_HOMES: 'funeral_homes'
};

export const CONTACT_ORDERS = [
  { value: 'created_at', label: config().ASBLEGACY ? 'Date Init' : 'Date Added' },
  { value: 'updated_at', label: 'Date Updated' },
  config().ASBLEGACY && {
    value: 'transferred_at',
    label: 'Date Added'
  },
  config().CFGSNE && {
    value: 'transferred_at',
    label: 'Date Transferred'
  },
  { value: 'first_name', label: 'First Name' },
  { value: 'last_name', label: 'Last Name' },
  { value: 'address', label: 'Address' },
  { value: 'city', label: 'City' },
  { value: 'zip_code', label: 'Zip Code' },
  { value: 'county', label: 'County' },
  { value: 'phone', label: 'Phone' },
  { value: 'email', label: 'Email' },
  { value: 'dob', label: 'DOB' },
  { value: 'status_text', label: 'Status' },
  { value: 'source_text', label: 'Source' },
  { value: 'color', label: 'Color' },
  { value: 'follow_up_date', label: 'Follow Up' },
  config().MYSIBAGENT && {
    value: 'tm_date_created_at',
    label: 'TM Date Create'
  }
].filter(Boolean);

export const CONTACT_GROUPS = user => {
  let data = [
    { value: 'mine', label: 'Mine' },
    ...(user.roles.includes('view_downline_contacts') ? [{ value: 'downline', label: 'Downline' }] : []),
    { value: 'events_contacts', label: 'Shared Calendar' },
    { value: 'shared_policy', label: 'Shared Policy' }
  ];

  if (!config().DISABLED_POOLS) {
    data = [...data, { label: 'Pools', value: 'pools', isDisabled: true }];
  }
  return data;
};

export const CONTACT_RM_GROUPS = user => {
  let data = [
    { value: 'mine', label: 'Mine' },
    { value: 'events_contacts', label: 'Shared Calendar' },
    { value: 'shared_policy', label: 'Shared Policy' }
  ];

  if (!config().DISABLED_POOLS) {
    data = [...data, { label: 'Pools', value: 'pools', isDisabled: true }];
  }
  return data;
};

export const DASHBOARD_CONTACT_GROUPS = user => {
  let data = [
    { value: 'mine', label: 'Mine' },
    ...(user.roles.includes('view_downline_contacts') ? [{ value: 'downline', label: 'Downline' }] : []),
    { value: 'events_contacts', label: 'Shared Calendar' }
  ];

  if (!config().DISABLED_POOLS) {
    data = [...data, { label: 'Pools', value: 'pools', isDisabled: true }];
  }
  return data;
};

export const DEFAULT_CONTACT_SOURCE = { value: 'general_list', label: 'General List' };
export const DEFAULT_CONTACT_STATUS = { value: 'prospect', label: 'Prospect' };

export const ACTIVITY_STATUSES_HEADER = {
  value: '',
  label: <p className="option-header">Activity Status</p>,
  isDisabled: true
};
export const APPOINTMENT_STATUSES_HEADER = {
  value: '',
  label: <p className="option-header">Appointment Status</p>,
  isDisabled: true
};

export const CLIENT_STATUSES_HEADER = {
  value: '',
  label: <p className="option-header">Client Status</p>,
  isDisabled: true
};

export const splitStatuses = statuses => {
  let activityStatuses = [ACTIVITY_STATUSES_HEADER];
  let appointmentStatuses = [APPOINTMENT_STATUSES_HEADER];
  let clientStatuses = [CLIENT_STATUSES_HEADER];

  const matchedLabels = new Set();

  const pushMatchedStatuses = (sourceArray, targetArray) => {
    sourceArray.forEach(status => {
      let dbStatus = statuses.find(el => el.label === status.label);
      if (dbStatus) {
        targetArray.push(dbStatus);
        matchedLabels.add(dbStatus.label);
      }
    });
  };

  pushMatchedStatuses(ACTIVITY_STATUSES, activityStatuses);
  pushMatchedStatuses(APPOINTMENT_STATUSES, appointmentStatuses);
  pushMatchedStatuses(CLIENT_STATUSES, clientStatuses);

  statuses.forEach(status => {
    if (!matchedLabels.has(status.label)) {
      activityStatuses.push(status);
    }
  });

  return [...activityStatuses, ...appointmentStatuses, ...clientStatuses];
};

export const ACTIVITY_STATUSES = [
  { label: 'Prospect' },
  { label: 'Data Captured' },
  { label: 'Call Back' },
  { label: 'No Interest/Not Set' },
  { label: 'No Answer' },
  { label: 'No Answer 2' },
  { label: 'Disconnect/Wrong#' },
  { label: 'Door Knock' },
  { label: 'Sent Info/Touch 4' },
  { label: 'Referral' },
  { label: 'DNC/Take Off List' },
  { label: 'Deceased' }
];

export const APPOINTMENT_STATUSES = [
  { label: 'Appointment Set' },
  { label: 'Follow Up' },
  { label: 'Fact Finder Done' },
  { label: 'AEP Go-Back' },
  { label: 'Nothing There' },
  { label: 'Not Kept/Porched' }
];

export const CLIENT_STATUSES = [
  { label: 'Submitted/Pending' },
  { label: 'Cancel/Withdrawn' },
  { label: 'Declined' },
  { label: 'Client/Issued' },
  { label: 'Lapsed' },
  { label: 'Deceased Client' }
];

export const CONTACT_DISPOSITIONS = [
  { value: 'active', label: 'Active' },
  { value: 'sold', label: 'Sold' },
  { value: 'no_sale', label: 'No Sale' },
  { value: 'nq_usd', label: 'NQ $' },
  { value: 'nq_health', label: 'NQ Health' },
  { value: 'no_show', label: 'No Show' }
];

export const CONTACT_POLICY_STATUSES = (user = null) => {
  if (config().CAREINADVANCE) {
    return [
      { value: 'active', label: 'Active' },
      { value: 'not_policyholder', label: 'Not Policy Holder' },
      { value: 'not_active_policyholder', label: 'Not Active Policy Holder' }
    ];
  }

  if (user?.aris) {
    return [
      { value: 'active_issued', label: 'Active - Issued' },
      { value: 'not_policyholder', label: 'Not Policy Holder' },
      { value: 'not_active_policyholder', label: 'Not Active Policy Holder' },
      { value: 'active_submitted', label: 'Active - Submitted' },
      { value: 'active_policy_holder', label: 'Active Policy Holder' }
    ];
  }

  return [
    { value: 'active_issued', label: 'Active - Issued' },
    { value: 'not_policyholder', label: 'Not Policy Holder' },
    { value: 'not_active_policyholder', label: 'Not Active Policy Holder' },
    { value: 'active_submitted', label: 'Active - Submitted' }
  ];
};

export const DATA_TYPE = [
  { value: 'contact', label: 'Contact' },
  { value: 'spouse_first_name', label: 'Spouse' }
];

export const PLAN_TYPE = (() => {
  if (config().BROKERAGEASB) {
    return [
      { value: 'medsupp', label: 'Med Supp' },
      { value: 'ma', label: 'Med Advantage' }
    ];
  }

  return [
    { value: 'life', label: 'Life' },
    { value: 'medsupp', label: 'Med Supp' },
    { value: 'ma', label: 'Med Advantage' },
    { value: 'health', label: 'Health' },
    { value: 'annuity', label: 'Annuity' }
  ];
})();

export const COLORS = {
  // do not change downcase of colors to uppercase
  red: '#ff4113',
  orange: '#f39500',
  yellow: '#ffb900',
  green: '#02be4d',
  cyan: '#00c5e4',
  dark_blue: '#334364',
  default: '#ddd'
};

export const SKIP_FIELD = 'skip_this_field';

const ACTIVITY_TRACKING_FIELDS = !config().DISABLED_ACTIVITY_TRACKING
  ? [
      { value: 'attempts', label: 'Attempts' },
      { value: 'contact', label: 'Contact' },
      { value: 'product_info', label: 'Product Info' },
      { value: 'appt_set', label: 'Appt Set' }
    ]
  : [];

const AFTERCARE_FIELDS = [
  { value: 'date_of_death', label: 'Date Of Death' },
  { value: 'associated_deceased', label: 'Associated Deceased' },
  { value: 'relationship_to_deceased', label: 'Relationship To Deceased' }
];

const CAREINADVANCE_FIELDS = config().CAREINADVANCE
  ? [...AFTERCARE_FIELDS, { value: 'face_amount', label: 'Face Amount' }]
  : [];

const MYSIBAGENT_FIELDS = config().MYSIBAGENT
  ? [
      { value: 'mr_age', label: 'MrAge' },
      { value: 'mrs_age', label: 'MrsAge' },
      { value: 'ins_comp', label: 'InsComp' },
      { value: 'tm_date_created_at', label: 'TM Date Created' },
      { value: 'tm_uid', label: 'TM ID' }
    ]
  : [];

const REFERRED_BY_FIELD = config().REFERRED_BY ? [{ value: 'referred_by', label: 'Referred By' }] : [];
const COMPANY_FIELD = config().COMPANY_FIELD ? [{ value: 'company', label: 'Company' }] : [];
export const AFFINITY_FIELDS = config().AFFINITY
  ? [
      { value: 'affinity', label: 'Affinity' },
      { value: 'affinity_name', label: 'Affinity Name' },
      { value: 'affinity_campaign', label: 'Affinity Campaign' }
    ]
  : [];
export const CREDIT_UNION_FIELDS = config().CREDIT_UNION ? [{ value: 'credit_union', label: 'Credit Union' }] : [];

export const RM_CONTACT_ROLES = [
  { value: 'owner', label: 'Owner' },
  { value: 'vice_president', label: 'Vice President' },
  { value: 'administrator', label: 'Administrator' },
  { value: 'manager', label: 'Manager' },
  { value: 'marketer', label: 'Marketer' }
];

export const LINKED_CONTACTS_ROLES = [
  { value: 'spouse', label: 'Spouse' },
  { value: 'friend', label: 'Friend' },
  { value: 'referral', label: 'Referral' },
  { value: 'family_member', label: 'Family Member' },
  { value: 'coi', label: 'COI' },
  { value: 'other', label: 'Other' }
];

const IMPORT_DATA_CAPTURE_FIELDS = [
  { value: 'data_capture_type', label: 'Data Capture Type' },
  { value: 'data_capture_carrier', label: 'Data Capture Carrier' },
  { value: 'data_capture_premium', label: 'Data Capture Premium' },
  { value: 'data_capture_default', label: 'Data Capture Default' },
  { value: 'data_capture_spouse_type', label: 'Data Capture Spouse Type' },
  { value: 'data_capture_spouse_carrier', label: 'Data Capture Spouse Carrier' },
  { value: 'data_capture_spouse_premium', label: 'Data Capture Spouse Premium' },
  { value: 'data_capture_spouse_default', label: 'Data Capture Spouse Default' }
];

export const FIELDS = (user = null) => {
  return [
    { value: SKIP_FIELD, label: 'Skip this field' },
    { value: 'id', label: 'ID' },
    { value: 'first_name', label: 'First Name' },
    { value: 'last_name', label: 'Last Name' },
    { value: 'address', label: 'Address Line' },
    { value: 'address_2', label: 'Address Line 2' },
    { value: 'state', label: 'State' },
    { value: 'county', label: 'County' },
    { value: 'file_name', label: 'File Name' },
    { value: 'city', label: 'City' },
    { value: 'zip_code', label: 'Zip Code' },
    { value: 'lat', label: 'Latitude' },
    { value: 'lon', label: 'Longitude' },
    { value: 'phone', label: 'Phone' },
    { value: 'cell_phone', label: 'Mobile' },
    { value: 'verified_phone', label: 'Verified Mobile' },
    { value: 'alt2', label: config().MYASBFINANCIAL ? 'Phone 3' : user?.c_1 || user?.aris ? 'Alt 2' : 'Alt Phone' },
    { value: 'email', label: 'Email' },
    { value: 'status_id', label: 'Status' },
    { value: 'source_id', label: 'Source' },
    { value: 'color', label: 'Color' },
    { value: 'dob', label: 'DOB' },
    ...CAREINADVANCE_FIELDS,
    { value: 'birth_month', label: 'Birth Month' },
    { value: 'birth_day', label: 'Birth Day' },
    { value: 'birth_year', label: 'Birth Year' },
    { value: 'gender', label: 'Gender' },
    { value: 'spouse_dob', label: 'Spouse DOB' },
    { value: 'spouse_gender', label: 'Spouse Gender' },
    ...MYSIBAGENT_FIELDS,
    ...((config().ASB_SYSTEM && !config().BROKERAGEASB) || config().AEGLEADS
      ? [
          { value: 'life_cross_sell_opt_out', label: 'Life Cross Sell Opt Out' },
          { value: 'annuity_cross_sell_opt_out', label: 'Annuity Cross Sell Opt Out' },
          { value: 'medicare_cross_sell_opt_out', label: 'Medicare Cross Sell Opt Out' }
        ]
      : []),
    { value: 'spouse_first_name', label: config().SPOUSE ? 'Spouse First Name' : 'Spouse Name' },
    { value: 'spouse_last_name', label: 'Spouse Last Name' },
    { value: 'spouse_phone', label: 'Spouse Phone' },
    { value: 'category', label: 'Category' },
    {
      value: 'policy_status',
      label: config().MYASBFINANCIAL ? 'Official ASB Client Designation' : 'Policy Holder Status'
    },
    ...ACTIVITY_TRACKING_FIELDS,
    { value: 'products_in_house', label: 'Products Sold' },
    { value: 'notes', label: 'Notes' },
    { value: 'note', label: 'Free Form Notes' },
    { value: 'tags', label: 'Tags' },
    { value: 'text_permission', label: 'Text Permission' },
    { value: 'email_permission', label: 'Email Permission' },
    { value: 'call_permission', label: 'Call Permission' },
    { value: 'attachment', label: 'Attachment' },
    { value: 'funereal_services', label: 'Funeral Service' },
    { value: 'remains_dispositions', label: 'Disposition of Remains' },
    { value: 'languages', label: 'Languages' },
    { value: 'tobacco', label: 'Tobacco' },
    ...REFERRED_BY_FIELD,
    ...COMPANY_FIELD,
    ...(config(user).DATA_CAPTURE_DEFAULT_FIELDS ? IMPORT_DATA_CAPTURE_FIELDS : [])
  ];
};

const PLAN_POLICY_FIELDS = config().PLAN
  ? [
      { value: 'plan', label: 'Policy Plan' },
      { value: 'plan_id', label: 'Policy Plan ID' }
    ]
  : [];

export const POLICY_FIELDS = (() => {
  if (config().BROKERAGEASB) {
    return [
      { value: 'number', label: 'Policy #' },
      { value: 'policy_type', label: 'Policy Type' },
      { value: 'carrier', label: 'Carrier' },
      { value: 'premium', label: 'Annual Premium' },
      { value: 'effective_date', label: 'Effective Date' },
      { value: 'policy_notes', label: 'Policy Notes' },
      { value: 'status_policy', label: 'Policy Status' },
      { value: 'application_date', label: 'Application Date' }
    ];
  }
  let data = [
    { value: 'number', label: 'Policy #' },
    { value: 'policy_type', label: 'Policy Type' },
    { value: 'policy_source', label: 'Policy Source' },
    { value: 'carrier', label: 'Carrier' },
    { value: 'premium', label: 'Premium' },
    { value: 'sub_premium', label: 'Sub Premium' },
    { value: 'effective_date', label: 'Effective Date' },
    { value: 'mode', label: 'Mode' },
    { value: 'user_1_id', label: 'Writing Agent 1' },
    { value: 'user_2_id', label: 'Writing Agent 2' },
    { value: 'user_3_id', label: 'Writing Agent 3' },
    { value: 'policy_notes', label: 'Policy Notes' },
    ...PLAN_POLICY_FIELDS,
    { value: 'status_policy', label: 'Policy Status' },
    { value: 'sub_source_id', label: 'Sub Source Id' },
    { value: 'iss_source_id', label: 'Iss Source Id' },
    { value: 'est_annualized_comm', label: 'Est Annualized Comm' },
    { value: 'modal_prem_amt', label: 'Modal Prem Amt' },
    { value: 'application_date', label: 'Application Date' },
    { value: 'paid_to_date', label: 'Paid To Date' }
  ];

  if (config().CAREINADVANCE) {
    data = [
      ...data,
      { value: 'insured_name', label: 'Insured Name' },
      { value: 'product_description', label: 'Product Description' },
      { value: 'policy_split', label: 'Policy Split %' },
      { value: 'funeral_home', label: 'Funeral Home' },
      { value: 'funeral_home_id', label: 'Funeral Home ID' },
      { value: 'plan_years', label: 'Plan Years' },
      { value: 'pay_type', label: 'Pay Type' }
    ];
  }
  return data;
})();

export const CONTACT_PRODUCTS_IN_HOUSE = (() => {
  if (config().CAREINADVANCE) {
    return [
      { value: 'voyage', label: 'Voyage' },
      { value: 'course', label: 'Course' },
      { value: 'annuity', label: 'Annuity' },
      { value: 'family_away_from_home_plan', label: 'Family Away from Home Plan' }
    ];
  }
  if (config().BROKERAGEASB) {
    return [
      { value: 'advantage', label: 'Medicare Advantage' },
      { value: 'supplement', label: 'Medicare Supplement' },
      { value: 'medicare_part_d', label: 'Medicare Part D' },
      { value: 'health_other', label: 'Health (other)' },
      { value: 'other', label: 'Other' }
    ];
  }
  let data = [
    { value: 'life', label: 'Life' },
    { value: 'medsupp', label: 'MedSupp' },
    { value: 'ma', label: 'MA' },
    { value: 'health', label: 'Health' },
    { value: 'annuity', label: 'Annuity' },
    { value: 'other', label: 'Other' }
  ];

  if (config().MYASBAGENT_SYSTEM) {
    data = [...data, { value: 'dsnp', label: 'DSNP' }, { value: 'hospital_indemnity', label: 'Hospital Indemnity' }];
  }

  return data;
})();

export const RM_COMMISSIONS = [
  { value: 'F1', label: 'F1' },
  { value: 'F2', label: 'F2' },
  { value: 'F3', label: 'F3' },
  { value: 'F4', label: 'F4' },
  { value: 'F5', label: 'F5' },
  { value: 'F6', label: 'F6' },
  { value: 'AG1', label: 'AG1' },
  { value: 'AG2', label: 'AG2' },
  { value: 'AG3', label: 'AG3' },
  { value: 'AG4', label: 'AG4' },
  { value: 'AC1', label: 'AC1' },
  { value: 'AC2', label: 'AC2' }
];

export const RM_PRODUCTS = [
  { value: 'course', label: 'Course' },
  { value: 'voyage', label: 'Voyage' },
  { value: 'destinations', label: 'Destinations' },
  { value: 'transitions', label: 'Transitions' },
  { value: 'annuity', label: 'Annuity' }
];

export const PLAN_OPTIONS = [
  { value: 'single', label: 'Single' },
  { value: 'one', label: 'One' },
  { value: 'three', label: 'Three' },
  { value: 'five', label: 'Five' },
  { value: 'ten', label: 'Ten' }
];

export const INCLUDE_EXCLUDE = [
  { value: '=', label: '= - include' },
  { value: '!=', label: '!= - exclude' }
];

export const INCLUDE_EXCLUDE_OR = [
  { value: '=', label: '= - include' },
  { value: '!=', label: '!= - exclude' },
  { value: '||=', label: '|| - either/or' }
];

export const SURVIVOR_FIELDS = [
  { value: 'survivor_first_name', label: 'Survivor First Name' },
  { value: 'survivor_last_name', label: 'Survivor Last Name' },
  { value: 'mobile_phone', label: 'Mobile Phone' },
  { value: 'relationship', label: 'Relationship' }
];

export const previewLabel = (place, label, user = null) => {
  switch (place) {
    case 'products_in_house':
      return label
        .map(product => (CONTACT_PRODUCTS_IN_HOUSE.find(({ value }) => value === product) || {}).label)
        .join(', ');
    case 'policy_status':
      return (CONTACT_POLICY_STATUSES(user).find(el => el.value === label) || {}).label;
    case 'disposition':
      return (CONTACT_DISPOSITIONS.find(el => el.value === label) || {}).label;
    default:
      return '';
  }
};

export const SURVIVOR = (() => {
  let survivors_params = [];
  ['1', '2', '3', '4', '5', '6', '7'].forEach(index =>
    SURVIVOR_FIELDS.forEach(survivor =>
      survivors_params.push({ value: `${survivor.value}_${index}`, label: `${survivor.label} ${index}` })
    )
  );
  return survivors_params;
})();

export const EMPTY_OPTIONS = [
  { value: 'empty', label: 'Empty' },
  { value: 'not_empty', label: 'Not Empty' }
];

export const INCLUDE_TYPES = [{ value: 'include', label: 'Include' }];

export const SELECT_TYPES = [
  { value: 'include', label: 'Include' },
  { value: 'exclude', label: 'Exclude' }
];

export const TYPE_OPTIONS = [
  config().HRGAGENT ? { value: 'includes', label: 'Includes' } : { value: 'exactly', label: 'Exactly' },
  { value: 'starts_with', label: 'Starts With' },
  { value: 'ends_with', label: 'Ends With' },
  config().HRGAGENT ? { value: 'exactly', label: 'Exactly' } : { value: 'includes', label: 'Includes' },
  ...EMPTY_OPTIONS
];

export const AGE_OPTIONS = [
  { value: '>', label: '>' },
  { value: '<', label: '<' },
  { value: '=', label: '=' }
];

export const INCLUDE_EXCLUDE_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'include', label: 'Include' },
  { value: 'exclude', label: 'Exclude' }
];

export const DATE_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'on', label: 'On' },
  { value: 'range', label: 'Range' },
  { value: 'before', label: 'Before' },
  { value: 'after', label: 'After' },
  { value: 'none', label: 'None' }
];

export const COLORS_OPTIONS = [
  // value should be in downcase
  { value: '#ff4113', label: 'Red' },
  { value: '#f39500', label: 'Orange' },
  { value: '#ffb900', label: 'Yellow' },
  { value: '#02be4d', label: 'Green' },
  { value: '#00c5e4', label: 'Cyan' },
  { value: '#334364', label: 'Dark Blue' }
];

export const MONTHS = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

export const GENDERS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
];

const YES_OR_NO = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
];

export const AFFINITY = YES_OR_NO;
export const AML = YES_OR_NO;

export const RELATIONSHIP_OPTIONS = [
  { value: 'spouse', label: 'Spouse' },
  { value: 'child', label: 'Child' },
  { value: 'parent', label: 'Parent' },
  { value: 'family', label: 'Family' },
  { value: 'other', label: 'Other' }
];

export const RM_ADVANCED_SEARCH = [
  {
    field: 'tpm_name',
    type: 'exactly',
    value: ''
  },
  {
    field: 'net_face_amount_wtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'net_face_amount_mtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'net_face_amount_qtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'net_face_amount_ytd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'net_policy_count_wtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'net_policy_count_mtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'net_policy_count_qtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'net_policy_count_ytd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'gross_face_amount_wtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'gross_face_amount_mtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'gross_face_amount_qtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'contact_permissions',
    type: 'exactly',
    value: ''
  },
  {
    field: 'gross_face_amount_ytd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'gross_policy_count_wtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'gross_policy_count_mtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'gross_policy_count_qtd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'gross_policy_count_ytd',
    type: 'exactly',
    value: ''
  },
  {
    field: 'personal_email',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'main_or_branch',
    type: 'include',
    value: []
  },
  {
    field: 'main_or_branch',
    type: 'exclude',
    value: []
  },
  {
    field: 'website',
    type: 'exactly',
    value: ''
  },
  {
    field: 'ba_address_line',
    type: 'exactly',
    value: ''
  },
  {
    field: 'ba_address_line_2',
    type: 'exactly',
    value: ''
  },
  {
    field: 'ba_city',
    type: 'exactly',
    value: ''
  },
  {
    field: 'ba_state',
    type: 'exactly',
    value: ''
  },
  {
    field: 'ba_zip_code',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'facebook',
    type: 'exactly',
    value: ''
  },
  {
    field: 'linkedin',
    type: 'exactly',
    value: ''
  },
  {
    field: 'twitter',
    type: 'exactly',
    value: ''
  },
  {
    field: 'instagram',
    type: 'exactly',
    value: ''
  },
  {
    field: 'products',
    type: 'include',
    value: []
  },
  {
    field: 'products',
    type: 'exclude',
    value: []
  },
  {
    field: 'fax_number',
    type: 'exactly',
    value: ''
  },
  {
    field: 'home_address_city',
    type: 'exactly',
    value: ''
  },
  {
    field: 'home_address_state',
    type: 'exactly',
    value: ''
  },
  {
    field: 'home_address_zip_code',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'rm_phone',
    type: 'exactly',
    value: ''
  },
  {
    field: 'commissions',
    type: 'include',
    value: []
  },
  {
    field: 'commissions',
    type: 'exclude',
    value: []
  },
  {
    field: 'agency_lp_number',
    type: 'exactly',
    value: ''
  },
  {
    field: 'aml',
    type: 'include',
    value: []
  },
  {
    field: 'aml',
    type: 'exclude',
    value: []
  },
  {
    field: 'aml_renewal_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'license',
    type: 'exactly',
    value: ''
  },
  {
    field: 'license_renewal_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  }
];

export const ADVANCED_SEARCH = [
  {
    field: 'number',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'user_id',
    type: 'include',
    value: ''
  },
  {
    field: 'user_id',
    type: 'exclude',
    value: ''
  },
  {
    field: 'affinity',
    type: 'include',
    value: []
  },
  {
    field: 'affinity',
    type: 'exclude',
    value: []
  },
  {
    field: 'affinity_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'affinity_campaign',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'credit_union',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'first_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'last_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'middle_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'nick_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'gender',
    type: 'include',
    value: []
  },
  {
    field: 'gender',
    type: 'exclude',
    value: []
  },
  {
    field: 'address',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'home_address_line_1',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'home_address_line_2',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'address_2',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'state',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'county',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'file_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'city',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'zip_code',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'phone',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'cell_phone',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'alt2',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'alt3',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'verified_phone',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'email',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'status_id',
    type: 'include',
    value: []
  },
  {
    field: 'status_id',
    type: 'exclude',
    value: []
  },
  {
    field: 'source_id',
    type: 'include',
    value: []
  },
  {
    field: 'source_id',
    type: 'exclude',
    value: []
  },
  {
    field: 'original_source_id',
    type: 'include',
    value: []
  },
  {
    field: 'original_source_id',
    type: 'exclude',
    value: []
  },
  {
    field: 'agency_source_id',
    type: 'include',
    value: []
  },
  {
    field: 'agency_source_id',
    type: 'exclude',
    value: []
  },
  {
    field: 'color',
    type: 'include',
    value: []
  },
  {
    field: 'color',
    type: 'exclude',
    value: []
  },
  {
    field: 'dob_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_dob_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_first_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_last_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_phone',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'date_of_death',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'funeral_home',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'funeral_home_id',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'survivor_first_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'survivor_last_name',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'mobile_phone',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'survivor_relationship',
    type: 'include',
    value: []
  },
  {
    field: 'survivor_relationship',
    type: 'exclude',
    value: []
  },
  {
    field: 'associated_deceased',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_gender',
    type: 'include',
    value: []
  },
  {
    field: 'spouse_gender',
    type: 'exclude',
    value: []
  },
  {
    field: 'created_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'updated_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'products_in_house',
    type: 'include',
    value: []
  },
  {
    field: 'products_in_house',
    type: 'exclude',
    value: []
  },
  {
    field: 'funereal_services',
    type: 'include',
    value: []
  },
  {
    field: 'funereal_services',
    type: 'exclude',
    value: []
  },
  {
    field: 'remains_dispositions',
    type: 'include',
    value: []
  },
  {
    field: 'remains_dispositions',
    type: 'exclude',
    value: []
  },
  {
    field: 'languages',
    type: 'include',
    value: []
  },
  {
    field: 'languages',
    type: 'exclude',
    value: []
  },
  {
    field: 'dob_month',
    type: 'include',
    value: []
  },
  {
    field: 'dob_month',
    type: 'exclude',
    value: []
  },
  {
    field: 'dob_day',
    type: DATE_OPTIONS[1].value,
    value: ''
  },
  {
    field: 'notes_added_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'calendar_event',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'follow_up_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'note',
    type: 'exactly',
    value: ''
  },
  {
    field: 'notes',
    type: 'exactly',
    value: ''
  },
  {
    field: 'attempts',
    type: 'include',
    value: ''
  },
  {
    field: 'contact',
    type: 'include',
    value: ''
  },
  {
    field: 'product_info',
    type: 'include',
    value: ''
  },
  {
    field: 'appt_set',
    type: 'include',
    value: ''
  },
  {
    field: 'files_present',
    type: 'all',
    value: ''
  },
  {
    field: 'tobacco',
    type: 'include',
    value: []
  },
  {
    field: 'tobacco',
    type: 'exclude',
    value: []
  },
  {
    field: 'text_permission',
    type: 'include',
    value: ''
  },
  {
    field: 'email_permission',
    type: 'include',
    value: ''
  },
  {
    field: 'call_permission',
    type: 'include',
    value: ''
  },
  {
    field: 'life_cross_sell_opt_out',
    type: 'exclude',
    value: ''
  },
  {
    field: 'annuity_cross_sell_opt_out',
    type: 'exclude',
    value: ''
  },
  {
    field: 'medicare_cross_sell_opt_out',
    type: 'exclude',
    value: ''
  },
  {
    field: 'data_capture_data_type',
    type: 'include',
    value: []
  },
  {
    field: 'data_capture_data_type',
    type: 'exclude',
    value: []
  },
  {
    field: 'data_capture_plan_type',
    type: 'include',
    value: []
  },
  {
    field: 'data_capture_plan_type',
    type: 'exclude',
    value: []
  },
  {
    field: 'data_capture_carrier',
    type: 'include',
    value: []
  },
  {
    field: 'data_capture_carrier',
    type: 'exclude',
    value: []
  },
  {
    field: 'data_capture_carrier_text',
    type: 'exactly',
    value: ''
  },
  {
    field: 'data_capture_premium',
    type: 'exactly',
    value: ''
  },
  {
    field: 'face_amount',
    type: 'exactly',
    value: ''
  },
  { field: 'mr_age', type: AGE_OPTIONS[0].value, value: '' },
  { field: 'mrs_age', type: AGE_OPTIONS[0].value, value: '' },
  { field: 'ins_comp', type: 'exactly', value: '' },
  { field: 'tm_date_created_at', type: DATE_OPTIONS[0].value, value: '' },
  { field: 'tm_uid', type: 'include', value: '' },
  { field: 'tm_uid', type: 'exclude', value: '' },
  {
    field: 'tags',
    type: 'include',
    value: []
  },
  {
    field: 'tags',
    type: 'exclude',
    value: []
  },
  {
    field: 'tags',
    type: 'or',
    value: []
  },
  {
    field: 'policy_number',
    type: 'exactly',
    value: ''
  },
  { field: 'plan_id', type: TYPE_OPTIONS[0].value, value: '' },
  {
    field: 'plan',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'policy_status',
    type: 'include',
    value: []
  },
  {
    field: 'policy_status',
    type: 'exclude',
    value: []
  },
  {
    field: 'writing_agent_1_name',
    type: 'include',
    value: []
  },
  {
    field: 'writing_agent_2_name',
    type: 'include',
    value: []
  },
  {
    field: 'writing_agent_3_name',
    type: 'include',
    value: []
  },
  {
    field: 'writing_agent_1_number',
    type: 'include',
    value: []
  },
  {
    field: 'writing_agent_1_number',
    type: 'exclude',
    value: []
  },
  {
    field: 'writing_agent_2_number',
    type: 'include',
    value: []
  },
  {
    field: 'writing_agent_2_number',
    type: 'exclude',
    value: []
  },
  {
    field: 'writing_agent_3_number',
    type: 'include',
    value: []
  },
  {
    field: 'writing_agent_3_number',
    type: 'exclude',
    value: []
  },
  {
    field: 'policies_status',
    type: 'include',
    value: []
  },
  {
    field: 'policies_status',
    type: 'exclude',
    value: []
  },
  {
    field: 'role',
    type: 'include',
    value: []
  },
  {
    field: 'role',
    type: 'exclude',
    value: []
  },
  {
    field: 'policy_type',
    type: 'include',
    value: []
  },
  {
    field: 'policy_type',
    type: 'exclude',
    value: []
  },
  {
    field: 'policy_effective_day',
    type: DATE_OPTIONS[1].value,
    value: ''
  },
  {
    field: 'policy_effective_date',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'policy_note',
    type: 'exactly',
    value: ''
  },
  {
    field: 'policy_carrier',
    type: 'include',
    value: []
  },
  {
    field: 'policy_carrier',
    type: 'exclude',
    value: []
  },
  {
    field: 'rx_details_plan_type',
    type: 'include',
    value: []
  },
  {
    field: 'rx_details_plan_type',
    type: 'exclude',
    value: []
  },
  {
    field: 'rx_details_record_type',
    type: 'include',
    value: []
  },
  {
    field: 'rx_details_record_type',
    type: 'exclude',
    value: []
  },
  {
    field: 'rx_details_letter_sent',
    type: 'include',
    value: []
  },
  {
    field: 'rx_details_letter_sent',
    type: 'exclude',
    value: []
  },
  {
    field: 'import_type',
    type: 'include',
    value: []
  },
  {
    field: 'import_type',
    type: 'exclude',
    value: []
  },
  {
    field: 'policy_anniversary',
    type: 'include',
    value: ''
  },
  {
    field: 'older_policies',
    type: 'include',
    value: ''
  },
  {
    field: 'past_due_events',
    type: 'include',
    value: ''
  },
  {
    field: 'newsletter_sent',
    type: 'include',
    value: ''
  },
  {
    field: 'newsletter_opened',
    type: 'include',
    value: ''
  },
  {
    field: 'newsletter_sent_custom',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'newsletter_opened_custom',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'verified_mobile',
    type: 'include',
    value: ''
  },
  {
    field: 'verified_landline',
    type: 'include',
    value: ''
  },
  {
    field: 'referred_by',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'company',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'replacement',
    type: 'include',
    value: []
  },
  {
    field: 'replacement',
    type: 'exclude',
    value: []
  },
  {
    field: 'given_to',
    type: 'include',
    value: []
  },
  {
    field: 'given_to',
    type: 'exclude',
    value: []
  },
  {
    field: 'info_status',
    type: 'include',
    value: []
  },
  {
    field: 'info_status',
    type: 'exclude',
    value: []
  },
  {
    field: 'pc_notes',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'uninsurable_contact',
    type: 'include',
    value: ''
  },
  {
    field: 'uninsurable_spouse',
    type: 'include',
    value: ''
  },
  {
    field: 'health_notes',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'contact_current_income',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_current_income',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'contact_social_security',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_social_security',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'contact_pension',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_pension',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'contact_survivor_pension',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_survivor_pension',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'contact_total_income',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'spouse_total_income',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'other_household_income',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'total_household_income',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'mortgage_balance',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'total_net_worth',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'inheritance_amount',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'inheritance_notes',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'investment_notes',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'asset_protection',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'final_arrangements',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'estate_planning',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'inheritance',
    type: 'include',
    value: ''
  },
  {
    field: 'security_type',
    type: 'include',
    value: []
  },
  {
    field: 'security_type',
    type: 'exclude',
    value: []
  },
  {
    field: 'security_source',
    type: 'include',
    value: []
  },
  {
    field: 'security_source',
    type: 'exclude',
    value: []
  },
  {
    field: 'security_amount',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'security_owner',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'security_notes',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'security_created',
    type: DATE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'alt_phone',
    type: 'exactly',
    value: ''
  },
  {
    field: 'disposition',
    type: 'include',
    value: []
  },
  {
    field: 'policy_holder_id',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'medicare_id',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'a_start',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'b_start',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'ref_id',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'ma_plan',
    type: TYPE_OPTIONS[0].value,
    value: ''
  },
  {
    field: 'cms_plan_code',
    type: TYPE_OPTIONS[0].value,
    value: ''
  }
];
export const ARIS_FIELDS = [
  {
    value: 'replacement',
    label: 'Replacement'
  }
];

export const OWNER_FIELD = { value: 'user_id', label: 'Owner' };
export const LEGACY_ID_FIELD = { value: 'legacy_id', label: 'Legacy ID' };

const RM_ADVANCED_SEARCH_FIELDS = [
  {
    value: 'tpm_name',
    label: 'TPM Name'
  },
  {
    value: 'website',
    label: 'Website'
  },
  {
    value: 'ba_address_line',
    label: 'Business Address line'
  },
  {
    value: 'ba_address_line_2',
    label: 'Business Address line 2'
  },
  {
    value: 'ba_city',
    label: 'B.A. City'
  },
  {
    value: 'ba_state',
    label: 'B.A. State'
  },
  {
    value: 'ba_zip_code',
    label: 'B.A. Zip'
  },
  {
    value: 'facebook',
    label: 'Facebook'
  },
  {
    value: 'linkedin',
    label: 'LinkedIn'
  },
  {
    value: 'twitter',
    label: 'Twitter'
  },
  {
    value: 'instagram',
    label: 'Instagram'
  },
  {
    value: 'agency_source_id',
    label: 'Agency Source'
  },
  {
    value: 'fax_number',
    label: 'Fax Number'
  },
  {
    value: 'home_address_city',
    label: 'Home Address City'
  },
  {
    value: 'home_address_state',
    label: 'Home Address State'
  },
  {
    value: 'home_address_zip_code',
    label: 'Home Address Zip Code'
  },
  {
    value: 'alt_phone',
    label: 'Alt Phone'
  },
  {
    value: 'rm_phone',
    label: 'Phone (TPM and Funeral Home)'
  },
  {
    value: 'commissions',
    label: 'Commissions'
  },
  {
    value: 'main_or_branch',
    label: 'Main or Branch'
  },
  {
    value: 'contact_permissions',
    label: 'Contact Permissions'
  },
  {
    value: 'agency_lp_number',
    label: 'Life Pro #'
  },
  {
    value: 'aml',
    label: 'AML'
  },
  {
    value: 'aml_renewal_date',
    label: 'AML Renewal Date'
  },
  {
    value: 'license',
    label: 'License #'
  },
  {
    value: 'license_renewal_date',
    label: 'License Renewal Date'
  },
  {
    value: 'license_renewal_date',
    label: 'License Renewal Date'
  },
  {
    label: 'Net Face Amount (WTD)',
    value: 'net_face_amount_wtd'
  },
  {
    label: 'Net Face Amount (MTD)',
    value: 'net_face_amount_mtd'
  },
  {
    label: 'Net Face Amount (QTD)',
    value: 'net_face_amount_qtd'
  },
  {
    label: 'Net Face Amount (YTD)',
    value: 'net_face_amount_ytd'
  },
  {
    label: 'Net Policy Count (WTD)',
    value: 'net_policy_count_wtd'
  },
  {
    label: 'Net Policy Count (MTD)',
    value: 'net_policy_count_mtd'
  },
  {
    label: 'Net Policy Count (QTD)',
    value: 'net_policy_count_qtd'
  },
  {
    label: 'Net Policy Count (YTD)',
    value: 'net_policy_count_ytd'
  },
  {
    label: 'Gross Face Amount (WTD)',
    value: 'gross_face_amount_wtd'
  },
  {
    label: 'Gross Face Amount (MTD)',
    value: 'gross_face_amount_mtd'
  },
  {
    label: 'Gross Face Amount (QTD)',
    value: 'gross_face_amount_qtd'
  },
  {
    label: 'Gross Face Amount (YTD)',
    value: 'gross_face_amount_ytd'
  },
  {
    label: 'Gross Policy Count (WTD)',
    value: 'gross_policy_count_wtd'
  },
  {
    label: 'Gross Policy Count (MTD)',
    value: 'gross_policy_count_mtd'
  },
  {
    label: 'Gross Policy Count (QTD)',
    value: 'gross_policy_count_qtd'
  },
  {
    label: 'Gross Policy Count (YTD)',
    value: 'gross_policy_count_ytd'
  },
  {
    label: 'Products',
    value: 'products'
  }
];

export const ADVANCED_SEARCH_FIELDS = (user = null) => {
  const searchFields = [
    ...FIELDS(),
    ...RM_ADVANCED_SEARCH_FIELDS,
    {
      value: 'number',
      label: 'Contact Id'
    },
    {
      value: 'website',
      label: 'Website'
    },
    OWNER_FIELD,
    ...AFFINITY_FIELDS,
    ...CREDIT_UNION_FIELDS,
    {
      value: 'dob_date',
      label: 'Dob'
    },
    {
      value: 'date_of_death',
      label: 'Date Of Death'
    },
    {
      value: 'home_address_line_1',
      label: 'Home Address Line'
    },
    {
      value: 'personal_email',
      label: 'Personal Email'
    },
    {
      value: 'funeral_home',
      label: 'Funeral Home'
    },
    {
      value: 'funeral_home_id',
      label: 'Funeral Home ID'
    },
    {
      value: 'survivor_first_name',
      label: 'Survivor First Name'
    },
    {
      value: 'survivor_last_name',
      label: 'Survivor Last Name'
    },
    {
      value: 'mobile_phone',
      label: 'Survivor Mobile Phone'
    },
    {
      value: 'survivor_relationship',
      label: 'Survivor Relationship'
    },
    {
      value: 'associated_deceased',
      label: 'Associated Deceased'
    },
    {
      value: 'spouse_dob_date',
      label: 'Spouse Dob'
    },
    {
      value: 'net_face_amount_qtd',
      label: 'Net Face Amount (QTD)'
    },
    {
      value: 'created_date',
      label: 'Date Added'
    },
    {
      value: 'updated_date',
      label: 'Date Update'
    },
    {
      value: 'dob_month',
      label: 'Birth Date Month'
    },
    {
      value: 'original_source_id',
      label: 'Original Source'
    },
    {
      value: 'dob_day',
      label: 'Birth Date Day'
    },
    {
      value: 'notes_added_date',
      label: 'Date Note Added'
    },
    {
      value: 'calendar_event',
      label: 'Calendar Event'
    },
    {
      value: 'follow_up_date',
      label: 'Follow up'
    },
    {
      value: 'note',
      label: 'Free Form Notes'
    },
    {
      value: 'notes',
      label: 'Notes'
    },
    {
      value: 'files_present',
      label: 'Files Present'
    },
    {
      value: 'file_folder',
      label: 'File Folder'
    },
    {
      value: 'data_capture_data_type',
      label: 'Contact Type'
    },
    {
      value: 'data_capture_plan_type',
      label: 'Product Type'
    },
    {
      value: 'data_capture_carrier',
      label: 'Carrier'
    },
    {
      value: 'data_capture_carrier_text',
      label: 'Carrier Name'
    },
    {
      value: 'data_capture_premium',
      label: 'Premium'
    },
    {
      value: 'face_amount',
      label: 'Face Amount'
    },
    {
      value: 'policy_number',
      label: 'Policy Number'
    },
    {
      value: 'plan_id',
      label: 'Plan ID'
    },
    {
      value: 'plan',
      label: 'Plan'
    },
    {
      value: 'policies_status',
      label: 'Policies Status'
    },
    {
      value: 'role',
      label: 'Role'
    },
    {
      value: 'policy_type',
      label: 'Policy Type'
    },
    {
      value: 'life_cross_sell_opt_out',
      label: 'Life Cross Sell Opt Out'
    },
    {
      value: 'annuity_cross_sell_opt_out',
      label: 'Annuity Cross Sell Opt Out'
    },
    {
      value: 'medicare_cross_sell_opt_out',
      label: 'Medicare Cross Sell Opt Out'
    },
    {
      value: 'policy_effective_day',
      label: 'Policy Effective Day'
    },
    {
      value: 'policy_effective_date',
      label: 'Policy Effective Date'
    },
    {
      value: 'policy_note',
      label: 'Policy Note'
    },
    {
      value: 'policy_carrier',
      label: 'Policy Carrier'
    },
    {
      value: 'policy_status',
      label: 'Policy Status'
    },
    {
      value: 'rx_details_plan_type',
      label: 'RX Details: Product Type'
    },
    {
      value: 'rx_details_record_type',
      label: 'RX Details: Record Type'
    },
    {
      value: 'rx_details_letter_sent',
      label: 'RX Details: Letter Sent'
    },
    {
      value: 'import_type',
      label: 'Import Type'
    },
    {
      value: 'policy_anniversary',
      label: 'Policy Anniversary'
    },
    {
      value: 'older_policies',
      label: 'New Contacts for Older Policies'
    },
    {
      value: 'past_due_events',
      label: 'Past Due Events'
    },
    {
      value: 'writing_agent_1_name',
      label: 'Writing Agent 1 Name'
    },
    {
      value: 'role',
      label: 'Role'
    },
    {
      value: 'writing_agent_2_name',
      label: 'Writing Agent 2 Name'
    },
    {
      value: 'writing_agent_3_name',
      label: 'Writing Agent 3 Name'
    },
    {
      value: 'writing_agent_1_number',
      label: 'Writing Agent 1 Number'
    },
    {
      value: 'writing_agent_2_number',
      label: 'Writing Agent 2 Number'
    },
    {
      value: 'writing_agent_3_number',
      label: 'Writing Agent 3 Number'
    },
    {
      value: 'newsletter_sent',
      label: 'Newsletter Sent'
    },
    {
      value: 'newsletter_opened',
      label: 'Newsletter Opened'
    },
    {
      value: 'newsletter_sent_custom',
      label: 'Newsletter Sent Custom'
    },
    {
      value: 'newsletter_opened_custom',
      label: 'Newsletter Opened Custom'
    },
    {
      value: 'newsletter_sent_two_months_ago',
      label: 'Newsletter Sent Two Months Ago'
    },
    {
      value: 'newsletter_opened_two_months_ago',
      label: 'Newsletter Opened Two Months Ago'
    },
    {
      value: 'verified_mobile',
      label: 'Verified Mobile'
    },
    {
      value: 'verified_landline',
      label: 'Verified Landline'
    },
    {
      value: 'is_duplicate',
      label: 'Is Duplicate'
    },
    {
      value: 'middle_name',
      label: 'Middle Name'
    },
    {
      value: 'nick_name',
      label: 'Nick Name'
    },
    {
      value: 'alt2',
      label: config().MYASBFINANCIAL ? 'Phone 3' : user?.c_1 || user?.aris ? 'Alt 2' : 'Alt Phone'
    },
    {
      value: 'referred_by',
      label: 'Referred By'
    },
    {
      value: 'company',
      label: 'Company'
    },
    {
      value: 'replacement',
      label: 'Replacement'
    },
    {
      value: 'given_to',
      label: 'Given to'
    },
    {
      value: 'info_status',
      label: 'Info status'
    },
    {
      value: 'pc_notes',
      label: 'PC Notes'
    },
    {
      value: 'uninsurable_contact',
      label: 'Uninsurable Contact'
    },
    {
      value: 'uninsurable_spouse',
      label: 'Uninsurable Spouse'
    },
    {
      value: 'health_notes',
      label: 'Health Notes'
    },
    {
      value: 'contact_current_income',
      label: 'Current Monthly Income Contact'
    },
    {
      value: 'spouse_current_income',
      label: 'Current Monthly Income Spouse'
    },
    {
      value: 'contact_social_security',
      label: 'Social Security Monthly Contact'
    },
    {
      value: 'spouse_social_security',
      label: 'Social Security Monthly Spouse'
    },
    {
      value: 'contact_pension',
      label: 'Pension Monthly Contact'
    },
    {
      value: 'spouse_pension',
      label: 'Pension Monthly Spouse'
    },
    {
      value: 'contact_survivor_pension',
      label: 'Survivor Pension % Contact'
    },
    {
      value: 'spouse_survivor_pension',
      label: 'Survivor Pension % Spouse'
    },
    {
      value: 'contact_total_income',
      label: 'Total Monthly Income Contact'
    },
    {
      value: 'spouse_total_income',
      label: 'Total Monthly Income Spouse'
    },
    {
      value: 'other_household_income',
      label: 'Other Monthly Household Income (Contact)'
    },
    {
      value: 'total_household_income',
      label: 'Other Monthly Household Income (Spouse)'
    },
    {
      value: 'mortgage_balance',
      label: 'Mortgage Balance'
    },
    {
      value: 'total_net_worth',
      label: 'Total Net Worth'
    },
    {
      value: 'inheritance_amount',
      label: 'Amount of Inheritance'
    },
    {
      value: 'inheritance_notes',
      label: 'Inheritance Notes'
    },
    {
      value: 'investment_notes',
      label: 'Investment Asset Notes'
    },
    {
      value: 'asset_protection',
      label: 'Asset Protection LTC'
    },
    {
      value: 'final_arrangements',
      label: 'Final Arrangements'
    },
    {
      value: 'estate_planning',
      label: 'Estate Planning'
    },
    {
      value: 'inheritance',
      label: 'Inheritance'
    },
    {
      value: 'security_amount',
      label: 'Security Amount'
    },
    {
      value: 'security_owner',
      label: 'Security Owner'
    },
    {
      value: 'security_type',
      label: 'Security Type'
    },
    {
      value: 'security_source',
      label: 'Security Source'
    },
    {
      value: 'security_notes',
      label: 'Security Notes'
    },
    {
      value: 'security_created',
      label: 'Security Created'
    },
    {
      value: 'disposition',
      label: 'Disposition'
    },
    {
      value: 'policy_holder_id',
      label: 'Policy Holder ID'
    },
    {
      value: 'medicare_id',
      label: 'Medicare ID'
    },
    {
      value: 'a_start',
      label: 'A Start'
    },
    {
      value: 'b_start',
      label: 'B Start'
    },
    {
      value: 'ref_id',
      label: 'Ref ID'
    },
    {
      value: 'ma_plan',
      label: 'MA Plan'
    },
    {
      value: 'cms_plan_code',
      label: 'CMS Plan Code'
    }
  ];
  if (user?.c_1 || user?.aris) {
    const filteredFields = searchFields.filter(el =>
      user?.aris
        ? el.value !== 'phone' && el.value !== 'cell_phone' && el.value !== 'nick_name'
        : el.value !== 'phone' && el.value !== 'cell_phone'
    );
    return user?.aris
      ? [
          ...filteredFields,
          { value: 'phone', label: 'Phone' },
          { value: 'cell_phone', label: 'Alt 1' },
          { value: 'nick_name', label: 'Suffix' }
        ]
      : [...filteredFields, { value: 'phone', label: 'Primary Phone' }, { value: 'cell_phone', label: 'Alt 1' }];
  }
  if (config().MYASBFINANCIAL) {
    const filteredFields = searchFields.filter(el => !['alt2', 'alt3'].includes(el.value));
    return [...filteredFields, { value: 'alt2', label: 'Phone 3' }, { value: 'alt3', label: 'Phone 4' }];
  }
  return searchFields;
};

export const IMPORT_FILTERS = (() => {
  let data = [
    { value: '', label: 'All' },
    { value: 'title', label: 'Title' },
    { value: 'status', label: 'Status' },
    { value: 'importer', label: 'Importer' },
    { value: 'owner', label: 'Owner' },
    { value: 'created_at', label: 'Date Added' }
  ];
  if (!config().DISABLED_POOLS) {
    data = [...data, { value: 'pool', label: 'Pool' }];
  }
  return data;
})();

export const IMPORT_STATUSES = [
  { value: 'completed', label: 'Completed', color: '#02Be4d', background_color: 'rgba(2, 190, 77, 0.2)' },
  { value: 'init', label: 'Initialized', color: '#b2bac6', background_color: 'rgba(178, 186, 198, 0.2)' },
  { value: 'failed', label: 'Failed', color: '#f9422d', background_color: 'rgba(249, 66, 45, 0.2)' },
  { value: 'importing', label: 'Importing', color: '#fdd835', background_color: 'rgba(253, 216, 53, 0.2)' }
];

export const SOA_REQUEST_ACTIVITIES = [
  { value: 'sent', label: 'Sent', color: '#b2bac6', background_color: 'rgba(178, 186, 198, 0.2)' },
  { value: 'viewed', label: 'Viewed', color: '#0273be', background_color: 'rgba(2, 115, 190, 0.2)' },
  { value: 'signed', label: 'Signed', color: '#fdd835', background_color: 'rgba(253, 216, 53, 0.2)' },
  { value: 'rejected', label: 'Rejected', color: '#f9422d', background_color: 'rgba(249, 66, 45, 0.2)' },
  { value: 'completed', label: 'Completed', color: '#02Be4d', background_color: 'rgba(2, 190, 77, 0.2)' }
];

export const getExcludedImports = currentUser => {
  let excludedFields = [];
  const IMPORT_ONLY_ADMIN = [...AFFINITY_FIELDS];

  if (!currentUser.admin) excludedFields = [...excludedFields, ...IMPORT_ONLY_ADMIN];
  return excludedFields;
};

export const TABS_FOR_DISABLED_CONTACT = ['all_agents', 'events_contacts'];

export const ADDITIONAL_DETAILS_GIVEN_TO = [
  { value: 'Joel', label: 'Joel' },
  { value: 'Marc Macke', label: 'Marc Macke' },
  { value: 'Do not refer', label: 'Do not refer' }
];

export const ADDITIONAL_DETAILS_STATUSES = [
  { value: 'sold', label: 'Sold' },
  { value: 'not_sold', label: 'Not Sold' }
];

export const SECURITIES_TYPE = [
  { value: 'IRA', label: 'IRA' },
  { value: 'Joint', label: 'Joint' },
  { value: 'Roth IRA', label: 'Roth IRA' }
];

export const SECURITIES_SOURCE = [
  { value: 'Existing', label: 'Existing' },
  { value: 'ASB', label: 'ASB' }
];

export const FUNERAL_SERVICE = [
  { value: 'traditional_funeral_service', label: 'Traditional Funeral Service' },
  { value: 'graveside_service', label: 'Graveside Service' },
  { value: 'immediate_burial', label: 'Immediate Burial (No Service)' },
  { value: 'traditional_service_followed_by_cremation', label: 'Traditional Service followed by Cremation' },
  { value: 'cremation_with_memorial_service', label: 'Cremation with Memorial Service' },
  { value: 'direct_cremation', label: 'Direct Cremation (No Service)' },
  { value: 'traditional_funeral_service_ship_out', label: 'Traditional Funeral Service / Ship Out' },
  { value: 'direct_ship_out', label: 'Direct Ship Out (No Service)' },
  { value: 'other', label: 'Other/Various' }
];

export const DISPOSITION_OF_REMAINS = [
  { value: 'ground_burial', label: 'Ground Burial' },
  { value: 'natural_burial', label: 'Green/Natural Burial' },
  { value: 'urn_plot', label: 'Urn Plot' },
  { value: 'mausoleum_crypt', label: 'Mausoleum Crypt' },
  { value: 'niche', label: 'Niche' },
  { value: 'scattering_garden', label: 'Scattering Garden' },
  { value: 'scattering_at_sea', label: 'Scattering at Sea' }
];

export const LANGUAGE = [
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'chinese', label: 'Chinese' },
  { value: 'japanese', label: 'Japanese' },
  { value: 'korean', label: 'Korean' },
  { value: 'other', label: 'Other' }
];

export const RX_DETAILS_PLAN_TYPES = [
  { value: 'ma', label: 'Medicare Advantage' },
  { value: 'part_d', label: 'Part D' },
  { value: 'aca', label: 'ACA' }
];

export const RX_DETAILS_RECORD_TYPES = [
  { value: 'change', label: 'Change' },
  { value: 'no_change', label: 'No Change' },
  { value: 'not_selected', label: 'Not Selected' }
];

export const RX_DETAILS_LETTER_SENT = [
  { value: 'complete', label: 'Complete' },
  { value: 'incomplete', label: 'Incomplete' }
];

export const RX_DETAILS_FILL_SUPPLIES = [
  { value: '30', label: '30' },
  { value: '90', label: '90' },
  { value: '180', label: '180' }
];

export const IMPORT_FIELD_LIMIT = {
  first_name: 30,
  last_name: 30,
  middle_name: 30,
  database_field: 30,
  status_id: 30,
  nick_name: 30,
  address: 30,
  address_2: 30,
  county: 30,
  file_name: 250,
  city: 30,
  associated_deceased: 60,
  spouse_first_name: 30,
  spouse_last_name: 30,
  color: 30,
  note: 70000
};

export const TOBACCO = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

export const TM_UIDS = [
  { value: 'Kristine Rivers', label: 'Kristine Rivers' },
  { value: 'Lisa Smith', label: 'Lisa Smith' },
  { value: 'Rebekah Rickman', label: 'Rebekah Rickman' },
  { value: 'Senior Ins Brokers', label: 'Senior Ins Brokers' },
  { value: 'Tammy DeLeon', label: 'Tammy DeLeon' },
  { value: 'Dean Olberding', label: 'Dean Olberding' },
  { value: 'Crystal William', label: 'Crystal William' },
  { value: 'Keyshawn Jackson', label: 'Keyshawn Jackson' }
];

export const appTrackerLink = (user, contact = null) => {
  if (['demo'].includes(publicRuntimeConfig.APP_ENV) && ['7985'].includes(user?.number)) {
    return `https://apptracker.mymaddevelopment.com/submitted/start?agent_id=${user?.number}&lead_id=${contact?.number}`;
  }

  if (config().MYMADCRM && ['C19', 'C20'].includes(user?.company?.number)) {
    return `https://apptracker.mymadcrm.com/submitted/start?agent_id=${user?.number}&lead_id=${contact?.number}`;
  }

  if (user?.aris) {
    return `https://apptracker.aegleads.com/submitted/start?agent_id=${user?.number}&lead_id=${contact?.number}`;
  }
  if (user?.c_1) {
    return `https://aegsupport.com/submitted/start?agent_id=${user?.number}&lead_id=${contact?.number}`;
  }

  return;
};

export const phoneFieldName = (user, field) => {
  switch (field) {
    case 'phone':
      return user?.c_1 ? 'Primary Phone' : 'Phone';
    case 'cell_phone':
      return user?.c_1 || user?.aris ? 'Alt 1' : 'Mobile';
    case 'alt2':
      return config().MYASBFINANCIAL ? 'Phone 3' : user?.c_1 || user?.aris ? 'Alt 2' : 'Alt Phone';
  }
};
